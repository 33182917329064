export const videoPlayer = (function () {
	const allModal = document.querySelectorAll('.modal-wrapper');
	const allVideoPlayer = document.querySelectorAll('.video-player-image');
	const allInlineVideoPlayer = document.querySelectorAll('.inline-video-player--wrapper');
	
	const bindEvents = () => {
		allVideoPlayer.forEach((videoPlayer) => {
			const btnPlay = videoPlayer.querySelector('.btn-play');
			const videoId = btnPlay.dataset.modalId;
			
			btnPlay.addEventListener('click', () => {
				allModal.forEach(modal => {
					if (modal.id === videoId) {
						modal.removeAttribute('hidden');
					}
				});
				document.documentElement.classList.add('no-overflow');
			});
		});
		
		allInlineVideoPlayer.forEach((videoWrapper) => {
			const videoPlayer = videoWrapper.querySelector('video');
				
			videoPlayer.addEventListener('play', (e) => {
				e.target.closest('.hero-banner-image').querySelector('.banner-title').setAttribute('hidden', true);
			});
		});
	}

	const init = () => {
		bindEvents();
	}

	return {
		init
	}
})();