import $ from 'jquery';
import 'slick-carousel';

export const showcase = (function () {

	const init = () => {
		const $showcase = $('.showcase-wrapper');

		const slickOptions = {
			dots: false,
			arrows: false,
			infinite: true,
			autoplay: true,
			autoplaySpeed: 1000,
			slidesToShow: 2,
			centerMode: true,
			centerPadding: '20px',
			variableWidth: true
		};

		$showcase.slick(slickOptions);
	};


	return {
		init
	};
})();