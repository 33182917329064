export const header = (function () {

	const bindEvents = () => {
		const hamburgerMenu = document.querySelector('.cta-hamburger');
		const navigationList = document.querySelector('.navigation--list-wrapper');

		hamburgerMenu.addEventListener('click', () => {
			navigationList.classList.toggle('visible');
			document.documentElement.classList.toggle('no-overflow');
		});

		window.addEventListener('resize', () => {
			if (!document.querySelector('.navigation--list-wrapper').classList.contains('visible')) return;

			const isHambugerMenuVisible = window.matchMedia("(max-width: 1344px)").matches;

			if (!isHambugerMenuVisible) {
				navigationList.classList.remove('visible');
				document.documentElement.classList.remove('no-overflow');
			}
		});
	}

	const init = () => {
		bindEvents();
	}

	return {
		init
	}
})();