import $ from 'jquery';
import 'slick-carousel';

export const testimonials = (function () {
	const $testimonials = $('.testimonial-slides');

	const init = () => {
		$testimonials.slick();
	}

	return {
		init
	}
})();