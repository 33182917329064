export const formSubscribe = (function () {

	const showError = (field) => {
		if (field.closest('.field-group').querySelectorAll('.error-message').length) return;
		
		const errorMessage = field.dataset.error;
		const parentGroup = field.closest('.field-group');
		const errorElement = document.createElement('p');

		field.classList.add('error-status');
		errorElement.classList.add('error-message');
		errorElement.textContent = errorMessage;
		parentGroup.append(errorElement);
	}

	const hideError = (field) => {
		field.classList.remove('error-status');
		field.closest('.field-group').querySelector('.error-message').remove();
	}

	const bindEvents = () => {
		const frmSubscribe = document.querySelector('.frm-subscribe');

		frmSubscribe.addEventListener('submit', (e) => {
			e.preventDefault();

			const inputFields = e.target.querySelectorAll('input[type=text], input[type=email]');

			inputFields.forEach((field) => {
				if (field.type === 'email') {
					!field.value.match(/^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && showError(field);
					return;
				}

				if (field.value === '') {
					showError(field);
				}
			})
		});

		frmSubscribe.querySelectorAll('input[type=text], input[type=email]').forEach((field) => {
			field.addEventListener('blur', (e) => {
				if (!e.target.classList.contains('error-status')) return;

				if (e.target.type === 'email') {
					e.target.value.match(/^[a-zA-Z0-9_.±]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && hideError(e.target);
					return;
				}

				if (e.target.value !== '') {
					hideError(e.target);
				}
			});
		});
	}

	const init = () => {
		bindEvents();
	};

	return {
		init
	};
})();