import './main.scss';

import { modal } from './scripts/modal';
import { utility } from "./scripts/utility";
import { header } from './scripts/header';
import { heroCarousel } from './scripts/heroCarousel';
import { videoPlayer } from './scripts/videoPlayer';
import { testimonials } from './scripts/testimonials';
import { subscriptions } from './scripts/subscriptions';
import { showcase } from './scripts/showcase';
import { formSubscribe } from './scripts/formSubscribe';

modal.init();
utility.init();
header.init();
heroCarousel.init();
videoPlayer.init();
testimonials.init();
subscriptions.init();
showcase.init();
formSubscribe.init();