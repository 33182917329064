export const modal = (function () {
	const allModal = document.querySelectorAll('.modal-wrapper');
	
	const closeModal = (e) => {
		const target = e.target || e;

		const closestModal = target.classList.contains('modal-wrapper') ? target : target.closest('.modal-wrapper');

		closestModal.setAttribute('hidden', true);
		document.documentElement.classList.remove('no-overflow');
	};
	
	const bindEvents = () => {
		allModal.forEach((modal) => {
			const btnClose = modal.querySelector('.close');

			btnClose.addEventListener('click', closeModal);

			modal.addEventListener('click', (e) => {
				if (e.target.classList.contains('modal-wrapper')) {
					closeModal(e);
				}
			});
		});

		document.addEventListener('keyup', (e) => {
			if (!document.querySelectorAll('.modal-wrapper:not([hidden])').length) return;

			if (e.key === 'Escape') {
				closeModal(document.querySelector('.modal-wrapper:not([hidden])'));
			}
		});
	};

	const init = () => {
		bindEvents();
	};

	return {
		init
	};
})();