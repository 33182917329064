import $ from 'jquery';
import 'slick-carousel';

export const subscriptions = (function () {
	
	const init = () => {
		const isMobile = window.matchMedia("(max-width: 992px)").matches;
		const $subscriptions = $('.subscriptions-wrapper');
		const prevLabel = `<button type="button" class="carousel-arrow carousel-arrow--prev" aria-label="Previous Arrow"><svg width="29" height="90" viewBox="0 0 29 90" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M25.9858 2.89044L3.15384 44.9773L25.9858 87.0642" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>`;
		const nextLabel = `<button type="button" class="carousel-arrow carousel-arrow--next" aria-label="Next Arrow"><svg width="29" height="90" viewBox="0 0 29 90" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.16797 87.0642L26 44.9773L3.16797 2.89042" stroke="white" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/></svg></button>`;

		const slickOptions = {
			prevArrow: prevLabel,
			nextArrow: nextLabel,
			dots: false
		}

		$subscriptions.slick(slickOptions);
		if (!isMobile) {
			$subscriptions.slick('unslick');
		}

		window.onresize = () => {
			const isMobile = window.matchMedia("(max-width: 992px)").matches;

			!isMobile
				? document.querySelectorAll('.subscriptions-wrapper.slick-initialized').length && $subscriptions.slick('unslick')
				: !document.querySelectorAll('.subscriptions-wrapper.slick-initialized').length && $subscriptions.slick(slickOptions);
		}
	};

	return {
		init
	};
})();