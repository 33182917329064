export const utility = (function () {
	const getFullyear = () => {
		document.querySelector('.copy-right .copy-year').textContent = new Date().getFullYear();
	};

	const init = () => {
		getFullyear();
	}

	return {
		init
	};
})();